@import "../../theme/palette.sass";
@import "../../theme/variables.sass";

.break-screen-b {
  .fondoPantalla {
    background: white;
    background-size: 100% 100%;
    width: 100%;
    height: 1920px;
    top: 0px;
    bottom: 0px;
    overflow: hidden;

    @media screen and (orientation: landscape) {
      height: 1000px;
    }
  }

  .logo-parque{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10%;

    @media screen and (orientation: landscape) {
      padding-top: 50px;
      width: 150px;
    }
  }

  .logo-parque img{
    width: 100%;
  }

  .txt-principal-bajada{
    font-family: Frutiger-Light;
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    margin-top: 50px;
    color: #373535;

    &__bold {
      font-family: Frutiger;
      font-weight: bold;
    }

    @media screen and (orientation: landscape) {
      font-size: 30px;
      line-height: 38px;
      margin-top: 30px;
    }
  }

  .btn-animacion-ripple.home:before,
  .btn-animacion-ripple.home:after {
  content:'';
    position:absolute;
    top:0; right:0; bottom:0; left:0;
    border-radius:50%;
    border:1px solid #FA7A7A;
  }

  .btn-baliza{
    background-color: $color-red-botones !important;
    color: $color-white !important;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.2) !important;
    width: 100px;
    height: 100px;

    @media screen and (orientation: landscape) {
      width: 30px;
      height: 30px;
    }
  }

  .btn-baliza:disabled{
    background-color: $color-disabled !important;
    color: $color-gris-light !important;
  }

  .btn-baliza .material-icons{
    font-size: 50px;
  }

  .wrap-btn-baliza{
    text-align: center;
    margin-top: 90px;

    @media screen and (orientation: landscape) {
      margin-top: 30px;
    }
  }

  .bajada-texto-home{
    font-family: Frutiger-Light;
    font-style: normal;
    font-weight: 300;
    width: 100%;
    font-size: 40px;
    line-height: 48px;
    color: $color-gris-light;
    text-align: center;
    margin-top: 40px;

    @media screen and (orientation: landscape) {
      font-size: 26px;
      line-height: 30px;
      margin-top: 20px;
    }
  }

  .line-circle {
    border: 1px solid #41457C;
    border-radius: 50%;
    width: 2331px;
    height: 2331px;
    position: relative;
    bottom: -50px;
    left: calc(50% - 1151px);
    overflow: hidden;

    @media screen and (orientation: landscape) {
      width: 3003;
      height: 3003;
      left: calc(50% - 1160px);
    }

    .body-circle {
      border-radius: 50%;
      width: 2242px;
      height: 2242px;
      background: linear-gradient(90deg, #E62432 -0.48%, #114E92 100.76%);
      position: relative;
      bottom: -40px;
      left: 40px;
      overflow: hidden;

      .text-scan {
        font-family: Frutiger-Light;
        font-style: normal;
        font-weight: 300;
        font-size: 48px;
        line-height: 47px;
        align-items: center;
        text-align: center;
        color: white;
        margin-top: 175px;
    
        @media screen and (orientation: landscape) {
          font-size: 24px;
          line-height: 23px;
          margin-top: 100px;
        }
      }
    
      .text-arauco {
        font-family: Frutiger;
        font-weight: bold;
        font-size: 48px;
        line-height: 56px;
        align-items: center;
        text-align: center;
        color: white;
    
        @media screen and (orientation: landscape) {
          font-size: 26px;
          line-height: 34px;
        }
      }
    
      .qr-container {
        width: 100%;
        text-align: center;
    
        .qr { 
          margin: 30px auto;
          margin-bottom: 65px;
    
          @media screen and (orientation: landscape) {
            width: 100px;
            margin-top: 20px;
            margin-bottom: 40px;
          }
        }
      }

      .logo-container {
        position: relative;
        top: -20px;
        left: calc(50% - 175px);
        width: 350px;

        @media screen and (orientation: landscape) {
          width: 200px;
          left: calc(50% - 100px);

          .logo-img {
            width: 200px;
          }
        }
      }
    }
  }
}